function ValidarAutenticacao() {
    const token = localStorage.getItem("jwt");

    if (token) {
        return true;
    } else {
        return false;
    }
}

export default ValidarAutenticacao;