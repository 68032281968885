import React, { useState, useEffect } from "react";
import { Link, useNavigate, Navigate } from "react-router-dom";

import { motion } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import "./styles.css";
import mulherDirigindo from "../../../midia/imagens/mulher-dirigindo.png";
import logoVangoo from "../../../midia/imagens/logo-vangoo.png";
import api from '../../../controladores/api';

function PaginaLogin() {
    const navegacao = useNavigate();

    const [email, setEmail] = useState("");
    const [senha, setSenha] = useState("");

    useEffect(() => {
        document.title = "VanGoo | Login";
    }, []);

    const notificarErro = (e) => toast.error(e, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        icon: false,
        style: {
            borderRadius: '25px',
            fontFamily: 'Montserrat'
        }
    });

    function RealizarLogin() {
        let json = {
            email: email.trim(),
            senha: senha.trim()
        };

        api.post("/login", json)
        .then((res) => {
            var token = res.data.token;
            localStorage.setItem("jwt", token);
            
            navegacao('/painel');
        })
        .catch((err) => {
            notificarErro(err.response.data);
            return;
        })
    }

    return(
        <div className="container-fluid h-100 container-auth">
            <div className="row h-100 div-principal">
                <div className="col d-none d-md-block col-left bg-primary coluna-imagem">
                    <motion.div className="mx-auto w-100 h-100 p-5 justify-content-center align-items-center d-flex" initial={{ y: -1000 }} animate={{ y: 0 }} transition={{ type: 'spring', stiffness: 300 }}>
                        <img src={mulherDirigindo} alt="" className="imagem-mulher"/>
                    </motion.div>
                </div>
                <div className="col d-flex flex-column justify-content-center coluna-login">
                    <div className="text-center">
                        <motion.div className="mx-auto w-100 mt-5 justify-content-center align-items-center d-flex" initial={{ x: -1000 }} animate={{ x: 0 }} transition={{ type: 'spring', stiffness: 300 }}>
                            <img src={logoVangoo} className="logo-vangoo" />
                        </motion.div>
                        <div className="row mt-3">
                            <span className="span-acesso">ACESSO AO PAINEL</span>
                            <span className="span-mensagem-01">👋 Olá! É muito bom vê-lo por aqui.</span>
                        </div>
                    </div>
                    <div className="container-form mx-auto w-100 p-5">
                        <div className="row">
                            <div className="col d-flex">
                                <i className='fa fa-envelope my-auto'></i>
                                <motion.input className="form-control input-customizado" type="email" onChange={(e) => setEmail(e.target.value)} placeholder="E-mail" transition={{ type: 'spring', stiffness: 500 }} whileFocus={{ scale: 1.03 }} whileBlur={e => {}}/>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col d-flex">
                                <i className='fa fa-key my-auto'></i>
                                <motion.input className="form-control input-customizado" type="password" onChange={(e) => setSenha(e.target.value)} placeholder="Senha" transition={{ type: 'spring', stiffness: 500 }} whileFocus={{ scale: 1.03 }} whileBlur={e => {}}/>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <motion.button type="button" className="btn-customizado" onClick={RealizarLogin} transition={{ type: 'spring', stiffness: 500 }} whileHover={{ scale: 1.03 }} onHoverStart={e => {}} onHoverEnd={e => {}}>ACESSAR</motion.button>
                        </div>
                    </div>
                    <div className="row w-100 text-center mt-3 div-registro-grande">
                        <span className="span-mensagem-01">Ainda não tem sua conta?</span>
                        <motion.a href="/registro" className="btn-registrar span-mensagem-02" transition={{ type: 'spring', stiffness: 500 }} whileHover={{ scale: 1.04 }} onHoverStart={e => {}} onHoverEnd={e => {}}>REGISTRE-SE AGORA!</motion.a>
                    </div>
                    <div className="div-registro">
                        <div className="row h-100 align-items-center">
                            <div className="w-100 text-center">
                                <span className="text-light span-mensagem-01">Ainda não tem sua conta?</span>
                                <br />
                                <motion.a href="/registro" className="btn-registrar" transition={{ type: 'spring', stiffness: 500 }} whileHover={{ scale: 1.03 }} onHoverStart={e => {}} onHoverEnd={e => {}}>REGISTRE-SE AGORA!</motion.a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ToastContainer />
        </div>
    )
}

export default PaginaLogin