import React, { useState, useEffect } from "react";
import { Link, useNavigate, Navigate } from "react-router-dom";

import SideBar from "../../componentes/sidebar";
import api from '../../../controladores/api';

function PaginaPainel() {
    const navegacao = useNavigate();

    useEffect(() => {
        document.title = "VanGoo | Painel";
    }, []);

    return(
        <div className="wrapper">
        </div>
    )
}

export default PaginaPainel