import React, { useState, useEffect } from "react";
import { Link, useNavigate, Navigate } from "react-router-dom";

import { motion } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import InputMask from "react-input-mask";
import Select from "react-select"

import "./styles.css";
import mulherDirigindo from "../../../midia/imagens/mulher-dirigindo.png";
import logoVangoo from "../../../midia/imagens/logo-vangoo.png";
import Validadores from "../../../funcoes/validacoes";
import api from "../../../controladores/api";

function PaginaRegistro() {
    useEffect(() => {
        document.title = "VanGoo | Registro";
    }, []);

    const navegacao = useNavigate();

    const etapasRegistro = [
        {
            id: "TIPO_CONTA",
            titulo: "👋 Olá! É muito bom te ver por aqui.",
            subtitulo: "Para começar, selecione abaixo o seu perfil:"
        },
        {
            id: "INFORMACOES_PESSOAIS",
            titulo: "☝️ Para começar, precisamos de algumas informações pessoais suas...",
            subtitulo: "Não compartilharemos nenhuma de suas informações pessoais, fique tranquilo!"
        },
        {
            id: "SEGURANCA",
            titulo: "🔑 Agora vamos criar uma senha para você!",
            subtitulo: "Crie uma senha forte, pois ela é muito importante para sua segurança."
        },
        {
            id: "ENVIANDO_FORMULARIO",
            titulo: "😄 Legal! Registramos a sua conta.",
            subtitulo: "Agora você pode realizar o seu login, e aproveitar todos os recursos do vangoo!"
        }
    ];

    const [tipoCadastro, setTipoCadastro] = useState(null);

    const [nome, setNome] = useState("");
    const [sobrenome, setSobrenome] = useState("");
    const [telefone, setTelefone] = useState("");
    const [dataNascimento, setDataNascimento] = useState("");
    const [cpfCnpj, setCpfCnpj] = useState("");
    const [email, setEmail] = useState("");
    const [confirmaEmail, setConfirmaEmail] = useState("");

    const [senha, setSenha] = useState("");
    const [confirmaSenha, setConfirmaSenha] = useState("");

    const [etapaAtual, setEtapaAtual] = useState(0);

    const notificarErro = (e) => toast.error(e, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        icon: true,
        style: {
            borderRadius: '25px',
            fontFamily: 'Montserrat'
        }
    });

    const notificarSucesso = (e) => toast.success(e, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        icon: true,
        style: {
            borderRadius: '25px',
            fontFamily: 'Montserrat'
        }
    });

    function proximaEtapa() {
        setEtapaAtual((prevState) => prevState + 1);
    }

    function etapaAnterior() {
        setEtapaAtual((prevState) => prevState - 1);
    }

    function selecionarTipoCadastro(tipoCadastroSelecionado) {
        setTipoCadastro(tipoCadastroSelecionado)
        proximaEtapa();
    }

    function resetarTipoCadastro() {
        setTipoCadastro(null);
        etapaAnterior();
    }

    function validarTipoPerfil() {
        if (!tipoCadastro) {
            notificarErro("Por favor, selecione o seu perfil abaixo.");
            return;
        } else {
            proximaEtapa();
        }
    }

    function validarEmail(email) {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    }

    function validarInformacoesPessoais() {
        var retorno = true;
        var mensagem = "";

        if (nome === "") { notificarErro("Por favor, informe seu nome."); return; }
        if (sobrenome === "") { notificarErro("Por favor, informe seu sobrenome.");  return;  }
        if (telefone === "") { notificarErro("Por favor, informe seu celular.");  return;  }
        if (dataNascimento === "") { notificarErro("Por favor, informe sua data de nascimento."); return;  }
        if (cpfCnpj === "") { notificarErro("Por favor, informe seu CPF ou CNPJ."); return;  }
        if (email === "") { notificarErro("Por favor, informe seu e-mail."); return;  }
        if (confirmaEmail === "") { notificarErro("Por favor, confirme seu e-mail."); return;  }

        if (nome.length < 3) { notificarErro("Por favor, informe um nome válido."); return; }
        if (nome.length < 2) { notificarErro("Por favor, informe um sobrenome válido."); return; }

        if(Validadores.ExtrairNumerosString(telefone).length < 11) { notificarErro("Por favor, informe um número celular válido."); return; }
        if(Validadores.ExtrairNumerosString(cpfCnpj).length < 11) { notificarErro("Por favor, informe um CPF válido."); return; }

        if(!Validadores.ValidarCPF(cpfCnpj)) { notificarErro("Por favor, informe um CPF válido."); return; }

        if (!validarEmail(email)) { notificarErro("Por favor, informe um e-mail válido."); return; }
        if (!validarEmail(confirmaEmail)) { notificarErro("Por favor, informe um e-mail válido."); return; }

        if (confirmaEmail !== email) { notificarErro("Os e-mails não são correspondentes."); return;  }

        proximaEtapa();
    }

    function realizarCadastro() {
        if (senha.length < 6) { notificarErro("A sua senha precisa ter no mínimo 6 caracteres."); return; }

        if (senha === "") { notificarErro("Por favor, informe uma senha."); return; }
        if (confirmaSenha === "") { notificarErro("Por favor, confirme a senha informada."); return; }

        if (confirmaSenha !== senha) { notificarErro("As senhas informadas não coincidem."); return; }

        let json = {
            nome: nome,
            sobrenome: sobrenome,
            email: email,
            senha: senha,
            confirma_senha: confirmaSenha,
            tipo_cadastro: tipoCadastro,
            cpf: Validadores.ExtrairNumerosString(cpfCnpj),
            data_nascimento: dataNascimento
        };

        const config = {
            headers: {
                "Authorization": "Bearer 12C06591BB7087D39DEDBB9EA7512578DC7EA018EF25CAD92927CCFA73916E1F",
                "Content-Type": "application/json",
            },
        };
          

        api.post("/usuario", json, config)
        .then((res) => {
            proximaEtapa();
            notificarSucesso("Sua conta foi criada com sucesso!");
        })
        .catch((err) => {
            notificarErro(err.response.data);
            return;
        });
    }

    function realizarLogin() {
        navegacao("/login");
    }

    return(
        <div className="container-fluid h-100 container-auth">
            <div className="row h-100 div-principal">
                <div className="col d-none d-md-block col-left bg-primary coluna-imagem">
                    <motion.div className="mx-auto w-100 h-100 p-5 justify-content-center align-items-center d-flex" initial={{ y: -1000 }} animate={{ y: 0 }} transition={{ type: "spring", stiffness: 300 }}>
                        <img src={mulherDirigindo} alt="" className="imagem-mulher"/>
                    </motion.div>
                </div>
                <div className="col d-flex flex-column justify-content-center coluna-login">
                    <div className="text-center">
                        <motion.div className="mx-auto w-100 justify-content-center align-items-center d-flex" initial={{ x: -1000 }} animate={{ x: 0 }} transition={{ type: "spring", stiffness: 300 }}>
                            <img src={logoVangoo} className="logo-vangoo" />
                        </motion.div>
                        <div className="row mt-3">
                            <span className="span-acesso-02">{etapasRegistro[etapaAtual].titulo}</span>
                            <span className="span-mensagem-01">{etapasRegistro[etapaAtual].subtitulo}</span>
                        </div>
                    </div>
                    <div className="container-form mx-auto w-100 px-5 mt-5">
                        {etapasRegistro[etapaAtual].id === "TIPO_CONTA" && (
                            <div className="row d-flex g-2">
                                <div className="form-group col-md-6">
                                    <input type="radio" className="btn-check" name="options-outlined" id="btn-aluno" autoComplete="off" onChange={() => selecionarTipoCadastro("A")}/>
                                    <motion.label className="btn btn-opcao-selecao text-center w-100" for="btn-aluno" transition={{ type: 'spring', stiffness: 500 }} whileHover={{ scale: 1.03 }} onHoverStart={e => {}} onHoverEnd={e => {}}>
                                        <h4 className="my-auto nome-opcao">PASSAGEIRO</h4>

                                        <div className="form-row">
                                            <span className="p-3" style={{"font-size":"400%"}}><i className="fa fa-graduation-cap mx-auto" aria-hidden="true"></i></span>
                                        </div>

                                        <p className="mt-3 descricao-opcao">Quero encontrar um <strong>motorista ideal</strong>...</p>
                                    </motion.label>
                                </div>

                                <div className="form-group col-md-6">
                                    <input type="radio" className="btn-check" name="options-outlined" id="btn-motorista" autoComplete="off" onChange={() => selecionarTipoCadastro("M")}/>
                                    <motion.label className="btn btn-opcao-selecao text-center w-100" for="btn-motorista" transition={{ type: 'spring', stiffness: 500 }} whileHover={{ scale: 1.03 }} onHoverStart={e => {}} onHoverEnd={e => {}}>
                                        <h4 className="my-auto nome-opcao">MOTORISTA</h4>

                                        <div className="form-row">
                                            <span className="p-3" style={{"font-size":"400%"}}><i className="fa fa-bus mx-auto" aria-hidden="true"></i></span>
                                        </div>

                                        <p className="mt-3 descricao-opcao">Quero encontrar <strong>novos passageiros</strong>...</p>
                                    </motion.label>
                                </div>

                                <div className="row w-100 text-center div-registro-grande mt-5">
                                    <span className="span-mensagem-01">Já possui uma conta?</span>
                                    <motion.a href="/login" className="btn-registrar span-mensagem-02" transition={{ type: "spring", stiffness: 500 }} whileHover={{ scale: 1.04 }} onHoverStart={e => {}} onHoverEnd={e => {}}>ACESSE AGORA!</motion.a>
                                </div>
                            </div>
                        )}

                        {etapasRegistro[etapaAtual].id === "INFORMACOES_PESSOAIS" && (
                            <div className="informacoes-pessoais">
                                <div className="row g-2">
                                    <div className="col-md">
                                        <motion.input type="text" className="form-control w-100 input-customizado" placeholder="Informe o seu nome" maxLength={100} autoCapitalize="true" required id="input_nome" onChange={(e) => setNome(e.target.value)} value={nome} transition={{ type: 'spring', stiffness: 500 }} whileFocus={{ scale: 1.03 }} whileBlur={e => {}}/>
                                    </div>
                                    <div className="col-md">
                                        <motion.input type="text" className="form-control w-100 input-customizado" placeholder="Informe o seu sobrenome" maxLength={100} autoCapitalize="true" required id="input_sobrenome" onChange={(e) => setSobrenome(e.target.value)} value={sobrenome} transition={{ type: 'spring', stiffness: 500 }} whileFocus={{ scale: 1.03 }} whileBlur={e => {}}/>
                                    </div>
                                </div>

                                <div className="row g-2 mt-2">
                                    <div className="col-md">
                                        <motion.input type="date" className="form-control w-100 input-customizado" autoCapitalize="true" required id="input_data_nascimento" onChange={(e) => setDataNascimento(e.target.value)} value={dataNascimento}/>
                                    </div>
                                    <div className="col-md">
                                        <InputMask type="text" className="form-control w-100 input-customizado" placeholder="Informe o seu celular" id="input_telefone" required={true} mask="(99) 9 9999-9999" onChange={(e) => setTelefone(e.target.value)} value={telefone} transition={{ type: 'spring', stiffness: 500 }} whileFocus={{ scale: 1.03 }} whileBlur={e => {}}></InputMask>
                                    </div>
                                </div>

                                <div className="row g-2 mt-2">
                                    <div className="col-md">
                                        <motion.input type="email" className="form-control w-100 input-customizado" placeholder="Informe o seu e-mail" maxLength={255} required id="input_email" onChange={(e) => setEmail(e.target.value)} value={email} transition={{ type: 'spring', stiffness: 500 }} whileFocus={{ scale: 1.03 }} whileBlur={e => {}}/>
                                    </div>
                                </div>

                                <div className="row g-2 mt-2">
                                    <div className="col-md">
                                        <motion.input type="email" className="form-control w-100 input-customizado" placeholder="Confirme o seu e-mail" maxLength={255} required id="input_email_confirmar" onChange={(e) => setConfirmaEmail(e.target.value)} value={confirmaEmail} transition={{ type: 'spring', stiffness: 500 }} whileFocus={{ scale: 1.03 }} whileBlur={e => {}}/>
                                    </div>
                                </div>

                                <div className="row g-2 mt-2">
                                    <div className="col-md">
                                        <InputMask type="text" className="form-control w-100 input-customizado" placeholder="Informe o seu CPF" required={true} mask="999.999.999-99" id="input_cpf_cnpj" onChange={(e) => setCpfCnpj(e.target.value)} value={cpfCnpj} transition={{ type: 'spring', stiffness: 500 }} whileFocus={{ scale: 1.03 }} whileBlur={e => {}}></InputMask>
                                    </div>
                                </div>

                                <div className="row mt-2 g-2">
                                    <div className="col-md">
                                        <motion.button type="button" className="btn-secundario-customizado w-100" onClick={etapaAnterior} transition={{ type: 'spring', stiffness: 500 }} whileHover={{ scale: 1.03 }} onHoverStart={e => {}} onHoverEnd={e => {}}>VOLTAR</motion.button>
                                    </div>
                                    <div className="col-md">
                                        <motion.button type="button" className="btn-primario-customizado w-100" onClick={validarInformacoesPessoais} transition={{ type: 'spring', stiffness: 500 }} whileHover={{ scale: 1.03 }} onHoverStart={e => {}} onHoverEnd={e => {}}>CONTINUAR</motion.button>
                                    </div>
                                </div>
                            </div>
                        )}

                        {etapasRegistro[etapaAtual].id === "SEGURANCA" && (
                            <div className="informacoes-pessoais">
                                <div className="row g-2">
                                    <div className="col-md">
                                        <motion.input type="password" placeholder="Informe a sua senha" className="form-control w-100 input-customizado" maxLength={12} autoCapitalize="true" required id="input_endereco" onChange={(e) => setSenha(e.target.value)} value={senha} transition={{ type: 'spring', stiffness: 500 }} whileFocus={{ scale: 1.03 }} whileBlur={e => {}}/>
                                    </div>
                                </div>

                                <div className="row g-2 mt-2">
                                    <div className="col-md">
                                        <motion.input type="password" placeholder="Confirme a sua senha" className="form-control w-100 input-customizado" maxLength={12} autoCapitalize="true" required id="input_cep" onChange={(e) => setConfirmaSenha(e.target.value)} value={confirmaSenha} transition={{ type: 'spring', stiffness: 500 }} whileFocus={{ scale: 1.03 }} whileBlur={e => {}}/>
                                    </div>
                                </div>

                                <div className="row mt-5 g-2">
                                    <div className="col-md px-2">
                                        <motion.button type="button" className="btn-secundario-customizado w-100" onClick={etapaAnterior} transition={{ type: 'spring', stiffness: 500 }} whileHover={{ scale: 1.03 }} onHoverStart={e => {}} onHoverEnd={e => {}}>VOLTAR</motion.button>
                                    </div>
                                    <div className="col-md px-2">
                                        <motion.button type="submit" className="btn-primario-customizado w-100" onClick={realizarCadastro} transition={{ type: 'spring', stiffness: 500 }} whileHover={{ scale: 1.03 }} onHoverStart={e => {}} onHoverEnd={e => {}}>CONTINUAR</motion.button>
                                    </div>
                                </div>
                            </div>
                        )}

                        {etapasRegistro[etapaAtual].id === "ENVIANDO_FORMULARIO" && (
                            <div className="row g-2">
                                <div className="col-md px-2">
                                    <motion.button type="submit" className="btn-primario-customizado w-100" onClick={realizarLogin} transition={{ type: 'spring', stiffness: 500 }} whileHover={{ scale: 1.03 }} onHoverStart={e => {}} onHoverEnd={e => {}}>REALIZAR LOGIN</motion.button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <ToastContainer />
        </div>
    )
}

export default PaginaRegistro