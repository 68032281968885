import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import { FaBars, FaHome, FaLock, FaMoneyBill, FaUser, FaUsers, FaSchool, FaBus, FaCog, FaMapMarker } from "react-icons/fa";
import { AnimatePresence, motion } from "framer-motion";

import SidebarMenu from "../menuSidebar";
import logoVangoo from "../../../midia/imagens/logo-vangoo-branca.png";
import "./styles.css";

const routes = [
    {
        path: "/painel",
        name: "Início",
        icon: <FaHome />,
    },
    {
        path: "/passageiros",
        name: "Passageiros",
        icon: <FaUsers />,
    },
    {
        path: "/escolas",
        name: "Escolas",
        icon: <FaSchool />,
    },
    {
        path: "/veiculos",
        name: "Veículos",
        icon: <FaBus />,
    },
    {
        path: "/rotas",
        name: "Rotas",
        icon: <FaMapMarker />,
    },
    {
        path: "/perfil",
        name: "Meu Perfil",
        icon: <FaUser />,
        exact: true,
        subRoutes: [
            {
                path: "/perfil/ajustes",
                name: "Ajustes",
                icon: <FaCog />,
            },
            {
                path: "/perfil/seguranca",
                name: "Segurança",
                icon: <FaLock />,
            },
            {
                path: "/perfil/faturamento",
                name: "Faturamento",
                icon: <FaMoneyBill />,
            },
        ],
    },
];

const SideBar = () => {
    var rotaAtual = useLocation();
    const [aberto, setAberto] = useState(false);
    const abrir = () => setAberto(!aberto);

    const showAnimation = {
        hidden: {
            width: 0,
            opacity: 0,
            transition: {
                duration: 0.5,
            },
        },
        show: {
            opacity: 1,
            width: "auto",
            transition: {
                duration: 0.5,
            },
        },
    };

    if (rotaAtual.pathname !== '/' && rotaAtual.pathname !== '/login' && rotaAtual.pathname !== '/registro' && rotaAtual.pathname !== '/imagem') {
        return (
            <div className="main-container">
                <motion.div animate={{ width: aberto ? "200px" : "45px", transition: { duration: 0.5, type: "spring", damping: 10, }, }} className={`sidebar `} >
                    <div className="top_section">
                        <AnimatePresence> 
                            {aberto && (
                                <motion.img variants={showAnimation} initial="hidden" animate="show" exit="hidden" className="logo" src={logoVangoo}></motion.img>
                            )}
                        </AnimatePresence>
    
                        <div className="bars">
                            <FaBars onClick={abrir} />
                        </div>
                    </div>
                    <section className="routes">
                        {routes.map((route, index) => {
                            if (route.subRoutes) {
                                return (
                                    <SidebarMenu setAberto={setAberto} route={route} showAnimation={showAnimation} aberto={aberto} />
                                );
                            }
    
                            return (
                                <NavLink to={route.path} key={index} className="link" activeClassName="active">
                                    <div className="icon">{route.icon}</div>
                                    <AnimatePresence>
                                        {aberto && (
                                            <motion.div variants={showAnimation} initial="hidden" animate="show" exit="hidden" className="link_text my-auto"> {route.name} </motion.div>
                                        )}
                                    </AnimatePresence>
                                </NavLink>
                            );
                        })}
                    </section>
                </motion.div>
            </div>
        );
    }
};

export default SideBar;