import { BrowserRouter, Routes, Route } from "react-router-dom";

import PaginaLogin from "../paginas/login";
import PaginaRegistro from "../paginas/registro";
import PaginaPainel from "../paginas/painel";
import RotaPrivada from "./rotasPrivadas";
import SideBar from "../componentes/sidebar";

function Rotas() {
    return (
        <BrowserRouter>
            <SideBar /> 
            <Routes>
                <Route path="/login" element={<PaginaLogin />} />
                <Route path="/registro" element={<PaginaRegistro />} />
                <Route path="/painel" element={<RotaPrivada> <PaginaPainel /> </RotaPrivada>} />
            </Routes>
        </BrowserRouter>
    )
}

export default Rotas